<template>
  <v-container fluid no-gutters>
    <!-- <v-row v-if="global">
      <v-col sm="2"></v-col>
      <v-col sm="6" lg="6" style="text-align: center; min-height: 60px">
        <v-row
          justify="center"
          v-if="
            checkReadPermission($modules.sales.graph.slug) ||
              checkReadPermission($modules.sales.logs.slug) ||
              checkReadPermission($modules.sales.credits.slug)
          "
        >
          <transition name="slide-fade">
            <v-col
              align="center"
              cols="6"
              md="2"
              v-if="checkReadPermission($modules.sales.graph.slug)"
              v-show="btnShow"
            >
              <router-link :to="`/sales`">
                <v-icon color="#066a8c">mdi-finance</v-icon>
                <div class="salesBtn">SALES</div>
              </router-link>
            </v-col>
          </transition>
          <v-col
            align="center"
            cols="6"
            md="2"
            :class="[logClass ? 'btn_bg' : '']"
            v-if="checkReadPermission($modules.sales.logs.slug)"
          >
            <router-link :to="`/logs`">
              <v-icon color="#066a8c">mdi-history</v-icon>
              <div class="salesBtn">LOGS</div>
            </router-link>
          </v-col>
          <v-col
            align="center"
            cols="6"
            md="2"
            :class="[creditClass ? 'btn_bg' : '']"
            v-if="checkReadPermission($modules.sales.credits.slug)"
          >
            <router-link :to="`/credits`">
              <v-icon color="#066a8c">mdi-account-clock</v-icon>
              <div class="salesBtn">CREDITS</div>
            </router-link>
          </v-col>
          <v-col
            align="center"
            cols="6"
            md="2"
            :class="[refundClass ? 'btn_bg' : '']"
            v-if="checkReadPermission($modules.sales.refunds.slug)"
          >
            <router-link :to="`/refunds`">
              <v-icon color="#066a8c">mdi-cash-refund</v-icon>
              <div class="salesBtn">REFUNDS</div>
            </router-link>
          </v-col>
          <v-col
            align="center"
            cols="6"
            md="2"
            :class="[cancellationClass ? 'btn_bg' : '']"
            v-if="checkReadPermission($modules.sales.logs.slug)"
          >
            <router-link :to="`/cancellations`">
              <v-icon color="#066a8c">mdi-file-cancel</v-icon>
              <div class="salesBtn">CANCELLATIONS</div>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>

      <v-col cols="2" md="2" sm="12">
        <online-sales :reload="reloadOnlineSales"></online-sales>
      </v-col>
      <v-col cols="2" md="2" sm="12">
        <krews-sales :reload="reloadOnlineSales"></krews-sales>
      </v-col>
    </v-row> -->
<!--    <v-row>-->
<!--      <v-col sm="12" lg="1"></v-col>-->
<!--      <v-col sm="12" lg="9" style="text-align: center; min-height: 60px">-->
<!--        <SaleTopTab :salesClass="false" :logClass="false" :invoiceClass="false" :creditClass="false"  :refundClass="false"  :cancellationClass="false" />-->
<!--      </v-col>-->
<!--      <v-col sm="12" lg="2" style="text-align: center; min-height: 60px">-->
<!--        <v-row-->
<!--            justify="center"-->
<!--            v-if="-->
<!--            checkReadPermission($modules.sales.graph.slug) ||-->
<!--            checkReadPermission($modules.sales.logs.slug) ||-->
<!--            checkReadPermission($modules.sales.credits.slug) ||-->
<!--            checkReadPermission($modules.sales.refunds.slug) ||-->
<!--            checkReadPermission($modules.sales.void.slug)-->
<!--          "-->
<!--        >-->
<!--          <v-col-->
<!--              align="center"-->
<!--              cols="4"-->
<!--              md="4"-->
<!--              v-if="checkReadPermission($modules.sales.graph.slug)"-->
<!--              @click="configDialog = true"-->
<!--          >-->
<!--            <v-icon color="#066a8c">mdi-cog</v-icon>-->
<!--            <div class="salesBtn">Config</div>-->
<!--          </v-col>-->
<!--          <v-col cols="8" md="8">-->
<!--            <div class="text-center">-->
<!--              <v-menu offset-y>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <div dark v-bind="attrs" v-on="on">-->
<!--                    <v-icon color="#066a8c">mdi-wallet</v-icon>-->
<!--                    <div class="salesBtn">Wallet</div>-->
<!--                  </div>-->
<!--                </template>-->
<!--                <online-sales></online-sales>-->
<!--                <krews-sales></krews-sales>-->
<!--              </v-menu>-->
<!--            </div>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <FinanceTopBar v-if="global" />

    <div class="app">
      <div class="user-wrapper">
        <div>
          <div class="md-card md-theme-default">
            <div class="md-card-content">
              <v-row align="center">
                <v-col md="3" lg="4">
                  <p class="text-2xl font-semibold mb-0" >Online Wallet Details</p>
                </v-col>


                <v-col
                    md="4" lg="4"
                    justify="center"
                    v-if="
                    checkReadPermission($modules.sales.logs.slug) &&
                      checkReadPermission($modules.sales.logs.slug)
                  "
                >
                  <v-btn-toggle
                      v-model="toggle_mode"
                      @change="searchData"
                      mandatory
                      borderless
                      class="q-tabs shadow-0 bordered"
                  >
                    <v-btn value="un_settled">Un Settled Amount</v-btn>
                    <v-btn value="settled">Settled Amount</v-btn>
                  </v-btn-toggle>
                </v-col>

                <v-col md="5"  lg="4" >
                  <div class="d-flex justify-space-between ">
                    <span
                        class="header_title3"
                        v-if="settlement.amount && toggle_mode == 'un_settled'"
                    >
                    <v-btn
                        color="darken-1"
                        class="white--text blue-color"
                        @click="settleAmount"
                        text
                    >Settle Amount (
                      {{ settlement.net_revenue | toCurrency }} )</v-btn
                    >
                  </span>
                     <v-spacer/>
                    <div  v-if="toggle_mode == 'un_settled'">
                      <v-btn
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          @click="salesReportUnSettledExcelDownload"
                      >
                        <SvgIcon text="Export" >
                          <template v-slot:icon>
                            <ExportIcon/>
                          </template>
                        </SvgIcon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="2" v-if="toggle_mode == 'un_settled'">
                  <label for="">
                    Table Filter
                  </label>
                  <v-autocomplete
                      v-model="columns"
                      outlined
                      multiple
                      item-value="value"
                      item-text="name"
                      :items="columnData"
                      dense
                      hide-details="auto"
                      class="q-autocomplete shadow-0"
                  >
                    <template
                        v-if="columns.length == columnData.length"
                        v-slot:selection="{ index }"
                    >
                      <span v-if="index == 0">All Fields</span>
                    </template>
                    <template v-else v-slot:selection="{ item, index }">
                      <span v-if="index == 0">{{ item.name }}</span>
                      <span v-if="index == 1">, ...</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="ml-4" cols="2">
                  <label for="">
                    Time Period
                  </label>
                  <v-autocomplete
                      v-model="searchParams.time_intervel"
                      outlined
                      @change="changeFxn"
                      item-value="name"
                      item-text="title"
                      :items="timeDuration"
                      dense
                      hide-details="auto"
                      class="q-autocomplete shadow-0"
                  ></v-autocomplete>
                </v-col>

                <v-col class="ml-1" cols="4" v-if="flag">
                  <v-row>
                    <v-col cols="5" style="padding-right: 0">
                      <v-menu
                          v-model="menu1"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                              outlined
                              background-color="#fff"
                              class="text1"
                              v-model="date1Formatted"
                              readonly
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="searchParams.start_date"
                            @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="5">
                      <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                              class="text2"
                              v-model="date2Formatted"
                              outlined
                              background-color="#fff"
                              readonly
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="searchParams.end_date"
                            @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="2">
                      <v-flex xs1 class="ml-1 mt-1">
                        <v-btn
                            color="#00b0af"
                            height="48"
                            dark
                            @click="searchData"
                        >Go</v-btn
                        >
                      </v-flex>
                    </v-col>
                  </v-row>
                </v-col>

                <v-spacer></v-spacer>
              </v-row>
            </div>

            <div class="md-card-content">
              <div>
                <div
                    class="md-content md-table md-theme-default table_borders"
                    table-header-color="orange"
                    value
                >
                  <div
                      class="
                      md-content md-table-content md-scrollbar md-theme-default
                    "
                  >
                    <table class="table border-collapse ">
                      <thead class="">
                      <tr class="opacity-70 tr-rounded tr-neon">
                        <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('timestamp')"
                        >
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <div
                                class="md-table-head-label cursor-pointer"
                                @click="sortColumn('id')"
                            >
                              Timestamp
                              <v-icon class="ml-2" color="#fff" small>
                                mdi-sort{{
                                  orderBy == "id"
                                      ? "-" +
                                      (orderDir == "ASC"
                                          ? "ascending"
                                          : "descending")
                                      : ""
                                }}
                              </v-icon>
                            </div>
                            <div class="search_column mt-1">
                              <date-menu
                                  v-model="searchParams.timestamp"
                                  @change="getFilterData"
                                  :range="true"
                              >
                              </date-menu>
                            </div>
                          </div>
                        </th>
                        <th
                            class="md-table-head"
                            v-if="
                              checkTableColumnVisibility('transaction_id') &&
                                toggle_mode == 'un_settled'
                            "
                        >
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <div class="md-table-head-label">
                              Payment Transaction ID
                            </div>
                            <div class="search_column">
                              <v-text-field
                                  solo
                                  filled
                                  label="Payment Transaction ID"
                                  clearable
                                  v-model="searchParams.transaction_id"
                                  @change="getFilterData"
                              ></v-text-field>
                            </div>
                          </div>
                        </th>
                        <th
                            class="md-table-head"
                            v-if="
                              checkTableColumnVisibility('bill_no') &&
                                toggle_mode == 'un_settled'
                            "
                        >
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <div class="md-table-head-label">Receipt No</div>
                            <div class="search_column">
                              <v-text-field
                                  solo
                                  filled
                                  label="Receipt No"
                                  clearable
                                  v-model="searchParams.bill_no"
                                  @change="getFilterData"
                              ></v-text-field>
                            </div>
                          </div>
                        </th>
                        <th
                            class="md-table-head"
                            v-if="
                              checkTableColumnVisibility('name') &&
                                toggle_mode == 'un_settled'
                            "
                        >
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <div class="md-table-head-label">
                              Customer Name
                            </div>
                            <div class="search_column">
                              <v-autocomplete
                                  solo
                                  filled
                                  label="Name"
                                  v-model="searchParams.name"
                                  item-value="name"
                                  item-text="name"
                                  :items="names"
                                  :loading="isLoading"
                                  :search-input.sync="name"
                                  @change="getFilterData"
                              ></v-autocomplete>
                            </div>
                          </div>
                        </th>
                        <th
                            class="md-table-head"
                            v-if="
                              checkTableColumnVisibility('card') &&
                                toggle_mode == 'un_settled'
                            "
                        >
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <div class="md-table-head-label">Card</div>
                          </div>
                        </th>
                        <th class="md-table-head">
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <div class="md-table-head-label">
                              Sales Amount
                            </div>
                          </div>
                        </th>
                        <th class="md-table-head">
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <div class="md-table-head-label">
                              Payment Processing Rate
                            </div>
                          </div>
                        </th>
                        <th class="md-table-head">
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <div class="md-table-head-label">
                              Payment Processing Fees
                            </div>
                          </div>
                        </th>
                        <th class="md-table-head">
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <div class="md-table-head-label">VAT Amount</div>
                          </div>
                        </th>
                        <th class="md-table-head">
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <div class="md-table-head-label">Net Revenue</div>
                          </div>
                        </th>

                        <!-- Transaction status -->
                        <th
                            class="md-table-head"
                            style="position: relative; min-width: 155px"
                            v-if="
                              checkTableColumnVisibility(
                                'transaction_status'
                              ) && toggle_mode == 'un_settled'
                            "
                        >
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <div class="md-table-head-label">
                              Transaction Status
                            </div>
                          </div>
                        </th>
                        <th
                            class="md-table-head"
                            v-if="
                              isShowSelectAll && toggle_mode == 'un_settled'
                            "
                        >
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <v-checkbox
                                style="margin-top: -8px"
                                color="#66c8c8"
                                hide-details
                                dark
                                v-model="settleAll"
                                @click="checkAll"
                            ></v-checkbox>
                          </div>
                        </th>
                        <th
                            class="md-table-head"
                            v-if="toggle_mode == 'settled' && logDatas.length"
                        >
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <div class="md-table-head-label">Action</div>
                          </div>
                        </th>
                        <th
                            class="md-table-head"
                            v-if="toggle_mode == 'settled' && logDatas.length"
                        >
                          <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                          >
                            <div class="md-table-head-label">Invoice</div>
                          </div>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          class="md-table-row"
                          v-for="(data, index) in logDatas"
                          :key="index"
                      >
                        <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('timestamp')"
                        >
                          <div class="md-table-cell-container">
                            {{ data.timestamp | timeStamp }}
                          </div>
                        </td>
                        <td
                            class="md-table-cell"
                            v-if="
                              checkTableColumnVisibility('transaction_id') &&
                                toggle_mode == 'un_settled'
                            "
                        >
                          <div class="md-table-cell-container">
                            {{ data.payment_transaction_id }}
                          </div>
                        </td>
                        <td
                            class="md-table-cell"
                            v-if="
                              checkTableColumnVisibility('bill_no') &&
                                toggle_mode == 'un_settled'
                            "
                        >
                          <div class="md-table-cell-container">
                            {{ data.order_seq_no }}
                          </div>
                        </td>
                        <td
                            class="md-table-cell"
                            v-if="
                              checkTableColumnVisibility('name') &&
                                toggle_mode == 'un_settled'
                            "
                        >
                          <div class="md-table-cell-container">
                            <v-btn
                                v-if="data.customer_id"
                                small
                                color="#00b0af"
                                dark
                                @click="showUserModel(data.customer_id)"
                            >{{ data.customer_name }}</v-btn
                            >
                            <span v-else>Anonymous</span>
                          </div>
                        </td>
                        <td
                            class="md-table-cell"
                            v-if="
                              checkTableColumnVisibility('card') &&
                                toggle_mode == 'un_settled'
                            "
                        >
                          <div
                              class="md-table-cell-container text-capitalize"
                          >
                            {{ data.card_number }}
                          </div>
                        </td>
                        <td class="md-table-cell">
                          <div
                              class="md-table-cell-container text-capitalize"
                              v-if="toggle_mode == 'un_settled'"
                          >
                            {{ Number(data.transaction_amount) | toCurrency }}
                          </div>
                          <div
                              class="md-table-cell-container text-capitalize"
                              v-else
                          >
                            {{ Number(data.st_sales_amount) | toCurrency }}
                          </div>
                        </td>
                        <td class="md-table-cell">
                          <div
                              class="md-table-cell-container text-capitalize"
                              v-if="toggle_mode == 'un_settled'"
                          >
                            {{ commission.rate }}
                          </div>
                          <div
                              class="md-table-cell-container text-capitalize"
                              v-else
                          >
                            {{ data.st_commission_rate }}
                          </div>
                        </td>
                        <td class="md-table-cell">
                          <div
                              class="md-table-cell-container text-capitalize"
                              v-if="toggle_mode == 'un_settled'"
                          >
                            {{
                              getCommission(data.transaction_amount)
                                  | toCurrency
                            }}
                          </div>
                          <div
                              class="md-table-cell-container text-capitalize"
                              v-else
                          >
                            {{ data.st_commission_amount | toCurrency }}
                          </div>
                        </td>
                        <td class="md-table-cell">
                          <div
                              class="md-table-cell-container text-capitalize"
                              v-if="toggle_mode == 'un_settled'"
                          >
                            {{
                              calculateVAT(
                                  getCommission(data.transaction_amount)
                              ) | toCurrency
                            }}
                          </div>
                          <div
                              class="md-table-cell-container text-capitalize"
                              v-else
                          >
                            {{ data.st_vat_amount | toCurrency }}
                          </div>
                        </td>
                        <td class="md-table-cell">
                          <div
                              class="md-table-cell-container text-capitalize"
                              v-if="toggle_mode == 'un_settled'"
                          >
                            {{
                              calculateRevenue(data.transaction_amount)
                                  | toCurrency
                            }}
                          </div>
                          <div
                              class="md-table-cell-container text-capitalize"
                              v-else
                          >
                            {{ data.st_net_revenue | toCurrency }}
                          </div>
                        </td>
                        <td
                            class="md-table-cell"
                            v-if="
                              checkTableColumnVisibility('transaction_status')
                            "
                        >
                          <div
                              class="md-table-cell-container text-capitalize"
                          >
                            {{ data.transaction_status || "NA" }}
                          </div>
                        </td>
                        <td
                            v-if="
                              isShowSelectAll && toggle_mode == 'un_settled'
                            "
                        >
                          <v-checkbox
                              style="margin-top: -8px"
                              v-model="data.isSettle"
                              color="#66c8c8"
                              :value="data.isSettle"
                              @change="changeAmount"
                              @click="settleAll = false"
                              hide-details
                          ></v-checkbox>
                        </td>
                        <td
                            v-if="toggle_mode == 'settled' && logDatas.length"
                        >
                          <div @click="salesReportExcelDownload(data.timestamp)">
                            <SvgIcon class="download-btn" component="button" text="Export"
                                     type="button">
                              <template v-slot:icon>
                                <DownloadIcon/>
                              </template>
                            </SvgIcon>
                          </div>
                        </td>
                        <td
                            v-if="toggle_mode == 'settled' && logDatas.length"
                        >
                          <div class="text-center">
                            <div @click="downloadInvoice(data)" v-if="data.invoice_path">
                              <SvgIcon class="download-btn" component="button" text="Invoice"
                                       type="button">
                                <template v-slot:icon>
                                  <DownloadIcon/>
                                </template>
                              </SvgIcon>
                            </div>
                            <p v-else>
                              -
                            </p>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <v-row>
                  <v-col cols="4"></v-col>
                  <v-col cols="4">
                    <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
                  </v-col>
                  <v-col class="d-flex align-center justify-end" cols="4">
                    <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
                      <div style="width: 80px">


                        <v-select
                            v-model="perPage"
                            :items="[10, 15, 25, 50,100,500,1000,2000]"
                            :menu-props="{ bottom: true, offsetY: true }"
                            class="q-autocomplete text-sm"
                            flat
                            hide-details
                            solo
                            @change="searchData"
                        ></v-select>
                      </div>
                      <span>Per Page</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <customer-model v-bind="userModel" @close="userModel.userID = null" />
    <confirm-model
        v-bind="confirmOEDModel"
        @confirm="confirmWalletSettlement"
        @close="confirmOEDModel.id = null"
        :disable-confirm="!settlement.invoice"
    >
      <template #content>
        <v-file-input
            v-model="settlement.invoice"
            background-color="#fff"
            class="q-text-field shadow-0 mt-4"
            dense
            hide-details="auto"
            outlined
            prepend-icon
            prepend-inner-icon="mdi-paperclip"
            required
            accept=".xlsx,.xls,.pdf"
        />
      </template>
    </confirm-model>
  </v-container>
</template>

<script>
import moment from "moment";
// import SaleTopTab from "../../components/Sale/SaleTopTab.vue";
// import OnlineSales from "./OnlineSales.vue";
// import KrewsSales from "./KrewsSales.vue";
import CustomerModel from "../Clients/Customer/CustomerModel";
import FinanceTopBar from "@/components/Sale/FinanceTopBar.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import DownloadIcon from "@/assets/images/misc/download.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  props: {
    global: {
      type: Boolean,
      default: true,
    },
    eventId: {
      type: Number,
      default: null,
    },
    promotionId: {
      type: Number,
      default: null,
    },
    exportPermission: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SvgIcon,
    ExportIcon,
    DownloadIcon,
    FinanceTopBar,
    CustomerModel,
    // OnlineSales,
    // KrewsSales,
    // SaleTopTab,
  },
  data() {
    return {
      items: [],
      sports: [],
      datas: [],
      logDatas: [],
      searchFlag: false,
      date1: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      searchParams: {
        services: [],
        payment_method: [],
        timestamp: [],
        status_id: null,
        start_date: moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      },
      menu1: false,
      menu2: false,
      flag: false,
      getGroundbyID: [],
      timeDuration: [
        { name: "All", title: "All" },
        { name: "week", title: "This Week" },
        { name: "year", title: "This Year" },
        { name: "month", title: "This Month" },
        { name: "custom", title: "Custom Duration" },
      ],
      orderBy: "id",
      orderDir: "DESC",
      status_id: null,
      logClass: false,
      creditClass: false,
      refundClass: false,
      cancellationClass: false,
      page: 1,
      totalPages: 1,
      perPage: null,
      fieldData: {},
      btnShow: false,
      receiptData: { id: null },
      ticketData: { id: null },
      names: [],
      name: null,
      customerNames: [],
      customerName: null,
      country_id: null,
      payment_method_id: null,
      phones: [],
      phone: null,
      emails: [],
      email: null,
      isLoading: false,
      datemenu: false,
      duemenu: false,
      AgeRange: ["All", "18-25", "26-30", "31-35"],
      Age: [],
      toAge: null,
      fromAge: null,
      columnData: [],
      columnData_list: [
        { name: "Timestamp", value: "timestamp" },
        { name: "Receipt No", value: "bill_no" },
        { name: "Transaction ID", value: "transaction_id" },
        { name: "Customer Name", value: "name" },
        { name: "Card Number", value: "card" },
        { name: "Transaction Status", value: "transaction_status" },
      ],
      columns: [],
      columns_list: ["timestamp", "transaction_id", "bill_no", "name", "card"],
      booking_details: {},
      orderId: null,
      creditOrderId: null,
      checkPermission: false,
      userModel: { userID: null, type: "details" },
      showParticipants: false,
      participant: {},
      refresh: false,
      paymentMethodFromOnline: [
        {
          id: 15,
          name: "Online",
          status_id: 1,
          type: "online",
        },
        {
          id: 16,
          name: "Krews",
          status_id: 1,
          type: "online",
        },
      ],
      orderIds: null,
      settlement: {
        amount: 0,
        commission_amount: 0,
        commission_rate: "2.25% + 0.5 AED",
        vat_amount: 0,
        net_revenue: 0,
        data: [],
        invoice:null
      },
      commission: {
        pct: 2.25,
        extra: 0.5,
        rate: "2.25% + AED 0.5",
      },
      settledArr: [],
      settleAll: false,
      isShowSelectAll: false,
      confirmOEDModel: {
        id: null,
        title: null,
        description: null,
      },
      reloadOnlineSales: false,
      toggle_mode: "un_settled",
    };
  },
  watch: {
    page() {
      this.searchData();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    cashierName(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("cashier_name", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
  },
  computed: {
    date1Formatted() {
      return moment(this.searchParams.start_date, "YYYY-MM-DD").format(
          "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.end_date, "YYYY-MM-DD").format(
          "Do MMM YYYY"
      );
    },

    countries() {
      return this.$store.getters.getCountries.data;
    },
  },
  mounted() {
    this.columns = [];
    this.columns = this.columns_list;
    this.columnData = [];
    this.columnData = this.columnData_list;

    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    this.loadCommissionData().then(() => {
      this.searchData();
    });

    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },
  methods: {
    settleAmount() {
      // let sm = this.$options.filters.toCurrency(this.settlement.amount);
      this.confirmOEDModel = {
        id: Math.floor(Math.random() * 100 + 1),
        title: " Settle Amount",
        description:
            "<b>Sales Amount: </b>" +
            this.$options.filters.toCurrency(this.settlement.amount) +
            "<br><b>Payment Processing Fees: </b>" +
            this.$options.filters.toCurrency(this.settlement.commission_amount) +
            "<br><b>VAT Amount: </b>" +
            this.$options.filters.toCurrency(this.settlement.vat_amount) +
            "<br><b>Net Revenue: </b>" +
            this.$options.filters.toCurrency(this.settlement.net_revenue) +
            "<br>By clicking <b>Yes</b> amount will be settled. Do you need to continue your action ?",
        type: "update",
        data: this.settlement,
      };
    },
    /** only disable for particular date */
    confirmWalletSettlement(data) {
      const settlement = data.data;
      if (!settlement.invoice){
        this.showError("Please Attach Invoice");
        return;
      }
      let formData = new FormData();
      if (settlement.amount > 0) {
        for (const [key, value] of Object.entries(settlement)) {
          if (key !== "data") {
            formData.append(key, value);
          }
        }

        settlement.data.forEach((item, index) => {
          for (const [key, value] of Object.entries(item)) {
            formData.append(`data[${index}][${key}]`, value);
          }
        });

        formData.append("wallet_type", "online");

        this.settlement.invoice = null;
        this.showLoader("LOADING ... ");
        this.$http
            .post(`venues/sales/krews-wallet/settle`, formData, {
              headers: {
                "Content-Type": "multipart/form-data; boundary=${form._boundary}",
              },
            })
            .then((response) => {
              if (response.status == 200 && response.data.status == true) {
                this.hideLoader();
                this.resetSettlement();
                this.reloadOnlineSales = !this.reloadOnlineSales;
                this.searchData();
                this.showSuccess("Amount settled successfully");
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            });
      } else {
        this.errorChecker("Amount should be greater than 0");
      }
    },
    resetSettlement() {
      this.settlement.amount = 0;
      this.settlement.commission_amount = 0;
      this.settlement.commission_rate = this.commission.rate;
      this.settlement.vat_amount = 0;
      this.settlement.net_revenue = 0;
      this.settlement.data = [];
    },
    calculateRevenue(transaction_amount) {
      return (
          transaction_amount -
          this.getCommission(transaction_amount) -
          this.calculateVAT(this.getCommission(transaction_amount))
      );
    },
    checkAll() {
      this.logDatas.forEach((element) => {
        element.isSettle = this.settleAll;
      });
      this.changeAmount();
      this.$forceUpdate();
    },
    changeAmount() {
      this.resetSettlement();
      this.logDatas.map((item) => {
        if (item.isSettle == true) {
          let commission_amount = this.getCommission(item.transaction_amount);
          let vat_amount = this.calculateVAT(commission_amount);
          let net_revenue =
              item.transaction_amount - commission_amount - vat_amount;
          /** to fixed  */
          commission_amount = commission_amount.toFixed(2);
          vat_amount = vat_amount.toFixed(2);
          net_revenue = net_revenue.toFixed(2);

          let arr = {
            transaction_id: item.transaction_id,
            sales_amount: parseFloat(item.transaction_amount),
            commission_amount: parseFloat(commission_amount),
            vat_amount: parseFloat(vat_amount),
            net_revenue: parseFloat(net_revenue),
          };
          this.settlement.amount += parseFloat(item.transaction_amount);
          this.settlement.commission_amount += parseFloat(commission_amount);
          this.settlement.vat_amount += parseFloat(vat_amount);
          this.settlement.net_revenue += parseFloat(net_revenue);
          this.settlement.data.push(arr);
        }
      });

      this.$forceUpdate();
    },
    getCommission(amount) {
      return (amount * this.commission.pct) / 100 + this.commission.extra;
    },
    calculateVAT(amount) {
      return (amount * 5) / 100;
    },

    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    toggle(type, field, searchP) {
      this.$nextTick(() => {
        if (searchP) {
          if (
              this.searchParams[field] != null &&
              this.searchParams[field].length == this[type].length
          ) {
            this.searchParams[field] = [];
          } else {
            this.searchParams[field] = this[type].map((x) => x.id);
          }
        } else {
          if (this[field].length == this[type].length) {
            this[field] = [];
          } else {
            this.searchParams[field] = this[type];
          }
        }
        this.searchData();
      });
      this.$forceUpdate();
    },
    getIcon(type, field, searchP) {
      let icon = "mdi-checkbox-blank-outline";
      if (searchP) {
        if (
            this.searchParams[field] != null &&
            this.searchParams[field].length == this[type].length
        ) {
          icon = "mdi-close-box";
        }
        if (
            this.searchParams[field] != null &&
            this.searchParams[field].length > 0 &&
            this.searchParams[field].length != this[type].length
        )
          icon = "mdi-minus-box";
      } else {
        if (this[field].length == this[type].length) {
          icon = "mdi-close-box";
        }
        if (this[field].length > 0 && this[field].length != this[type].length)
          icon = "mdi-minus-box";
      }
      return icon;
    },

    filterSearch(type, val) {
      this.isLoading = true;
      this.$http
          .get("venues/customers/filters?field=" + type + "&search=" + val)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.isLoading = false;
              if (type == "name") {
                this.names = response.data.data;
                this.names.unshift(val);
                this.names.unshift("All");
              } else if (type == "mobile") {
                this.phones = response.data.data;
                this.phones.unshift(val);
                this.phones.unshift("All");
              } else if (type == "email") {
                this.emails = response.data.data;
                this.emails.unshift(val);
                this.emails.unshift("All");
              } else if (type == "cashier_name") {
                this.cashierNames = response.data.data;
                this.cashierNames.unshift(val);
                this.cashierNames.unshift("All");
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getFilterData() {
      this.page = 1;
      this.searchData();
    },
    searchData() {
      this.showLoader("Loading..");
      this.settleAll = false;
      this.resetSettlement();
      let url = "";
      url += "&type=" + this.toggle_mode;
      url += "&wallet_type=online";
      if (
          this.searchParams.time_intervel != "All" &&
          this.searchParams.time_intervel != null
      ) {
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
              .startOf("week")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
              .startOf("month")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
              .startOf("year")
              .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }
      if (this.searchParams.timestamp == null) {
        delete this.searchParams.timestamp;
      } else if (typeof this.searchParams.timestamp != "undefined") {
        if (this.searchParams.timestamp.length > 1) {
          this.searchParams.timestamp.sort((a, b) => new Date(a) - new Date(b));
        }
        url += "&timestamp=" + this.searchParams.timestamp;
      }
      if (this.searchParams.name == "All") {
        delete this.searchParams.name;
      } else if (this.searchParams.name) {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.bill_no) {
        url += "&bill_no=" + this.searchParams.bill_no;
      }
      if (this.searchParams.transaction_id) {
        url += "&transaction_id=" + this.searchParams.transaction_id;
      }
      this.searchParams.page = this.page;
      this.searchParams.per_page = this.perPage != null ? this.perPage : 10;
      this.searchParams.order_by = this.orderBy;
      this.searchParams.order_by_value = this.orderDir;
      this.$http
          .get(
              "venues/sales/krews-wallet?page=" +
              this.page +
              "&per_page=" +
              (this.perPage != null ? this.perPage : 10) +
              "&order_by=" +
              this.orderBy +
              "&sort_order=" +
              this.orderDir +
              url
          )
          .then((response) => {
            if (response.status == 200) {
              this.hideLoader();
              this.logDatas = response.data.data;
              this.isShowSelectAll = true;
              this.totalPages = response.data.total_pages;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    changeFxn() {
      if (this.searchParams.time_intervel == "custom") {
        this.searchParams.start_date = moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD");
        this.searchParams.end_date = moment().format("YYYY-MM-DD");
        this.flag = true;
      } else {
        this.flag = false;
        this.searchData();
      }
    },
    sortColumn(type) {
      if (type == this.orderBy) {
        this.orderDir = this.orderDir == "ASC" ? "DESC" : "ASC";
      } else {
        this.orderDir = "DESC";
      }
      this.orderBy = type;
      this.searchData();
    },
    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },
    loadCommissionData() {
      return new Promise((resolve, reject) => {
        this.$http
            .get("venues/sales/krews-wallet-commission?wallet_type=online")
            .then((response) => {
              if (response.status == 200) {
                let data = response.data.data;
                this.commission.pct = data.commission_pct;
                this.commission.extra = data.commission_extra;
                this.commission.rate =
                    data.commission_pct.toFixed(2) +
                    "% + " +
                    this.$options.filters.toCurrency(data.commission_extra);
                this.settlement.commission_rate = this.commission.rate;
                resolve(data);
              }
              reject(response);
            })
            .catch((error) => {
              this.errorChecker(error);
              reject(error);
            });
      });
    },
    salesReportExcelDownload(timestamp = null, exportType = "excel") {
      this.showLoader("Downloading report");
      let url =
          "wallet_type=online&type=settled&export_type=" +
          exportType +
          "&batch_datetime=" +
          timestamp;
      this.$http
          .get("venues/sales/krews-wallet-settled/report/download?" + url, {
            responseType: "blob",
          })
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.downloadFile(response, "SettledReport_");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    salesReportUnSettledExcelDownload() {
      this.showLoader("Downloading report");
      let url =
          "wallet_type=online&type=un_settled&export_type=excel&order_by=id&sort_order=DESC";
      if (
          this.searchParams.time_intervel != "All" &&
          this.searchParams.time_intervel != null
      ) {
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
              .startOf("week")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
              .startOf("month")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
              .startOf("year")
              .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }
      if (this.searchParams.timestamp == null) {
        delete this.searchParams.timestamp;
      } else if (typeof this.searchParams.timestamp != "undefined") {
        if (this.searchParams.timestamp.length > 1) {
          this.searchParams.timestamp.sort((a, b) => new Date(a) - new Date(b));
        }
        url += "&timestamp=" + this.searchParams.timestamp;
      }
      if (this.searchParams.name == "All") {
        delete this.searchParams.name;
      } else if (this.searchParams.name) {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.bill_no) {
        url += "&bill_no=" + this.searchParams.bill_no;
      }
      if (this.searchParams.transaction_id) {
        url += "&transaction_id=" + this.searchParams.transaction_id;
      }
      this.$http
          .get("venues/sales/krews-wallet-unsettled/report/download?" + url, {
            responseType: "blob",
          })
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.downloadFile(response, "Un_SettledReport_");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    downloadInvoice(data) {
      window.open(this.s3BucketURL + data.invoice_path, '_blank')
    }
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
.book_time_log {
  color: #066a8c;
}
.md-card-header tr th:nth-child(1) {
  text-align: left !important;
}
.search_customer .v-input {
  margin: 0 0px;
}
.headline_ticket {
  background: #000;
  color: #fff;
}
.head {
  text-align: center;
  margin: 0 auto;
}
.headTicket {
  color: gray;
  font-weight: bold;
}
.valueTicket {
  color: #fff;
  border-bottom: 1px solid gray;
}
.sch_btn_class,
.sch_btn_event {
  padding: 0 35px !important;
}
.logTable tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}
span.header_title3 {
  float: right;
}
span.header_title2 {
  font-size: 14px;
  font-weight: 500;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: unset !important;
}
</style>
